<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                        color="primary"
                        dark
                        class="ml-auto ma-3 mr-1"
                        @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit Home Page</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                                ref="form"
                                v-model="validationRules.valid"
                                lazy-validation
                            >
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.new_release_text"
                                      :counter="50"
                                      :rules="validationRules.textRules"
                                      label="New release text*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                      v-model="editedItem.new_release_book_ids"
                                      :items="newReleaseBooksFilter"
                                      item-text="title"
                                      item-value="id"
                                      clearable
                                      deletable-chips
                                      chips
                                      :loading="isNrBooksLoaded"
                                      cache-items
                                      small-chips
                                      :rules="validationRules.multipleRules"
                                      label="New release book(s) (min. 3 characters required)"
                                      multiple
                                      @keyup="booksNrFilterKeyUp"
                                  >
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.best_seller_text"
                                      :counter="50"
                                      :rules="validationRules.textRules"
                                      label="Best seller text*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                      v-model="editedItem.best_seller_book_ids"
                                      :items="bestSellerBooksFilter"
                                      item-text="title"
                                      item-value="id"
                                      clearable
                                      deletable-chips
                                      chips
                                      :loading="isBsBooksLoaded"
                                      cache-items
                                      small-chips
                                      :rules="validationRules.multipleRules"
                                      label="Best seller book(s) (min. 3 characters required)"
                                      multiple
                                      @keyup="booksBsFilterKeyUp"
                                  >
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.shop_by_category_text"
                                      :counter="50"
                                      :rules="validationRules.textRules"
                                      label="Shop by category text*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="
                                      editedItem.shop_by_category_sub_text
                                    "
                                      :counter="100"
                                      :rules="validationRules.subTextRules"
                                      label="Shop by category sub text*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.blog_text"
                                      :counter="50"
                                      :rules="validationRules.textRules"
                                      label="Blog text*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      clearable
                                      v-model="editedItem.meta_title"
                                      maxLength="255"
                                      :counter="255"
                                      label="Meta Title"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                      v-model="editedItem.meta_keywords"
                                      label="Meta Keywords"
                                      maxLength="255"
                                      :counter="255"
                                      clearable
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                      v-model="editedItem.meta_description"
                                      label="Meta Description"
                                      maxLength="255"
                                      :counter="255"
                                      clearable
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-col cols="12" sm="12">
                          <v-text-field
                              v-model="editedItem.section_1_image_url"
                              :counter="200"
                              maxLength="200"
                              label="Section 1 image Url"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <h6>Section 1 Image</h6>
                        </v-col>
                        <ModuleMedia
                            moduleType="image"
                            moduleOf="home_page_section_1_image"
                            moduleMultiple="false"
                            :moduleImages="moduleImages"
                            :moduleImagesOld="moduleImagesOld"
                        ></ModuleMedia>
                        <v-col cols="12" sm="12">
                          <v-text-field
                              v-model="editedItem.section_2_image_url"
                              :counter="200"
                              maxLength="200"
                              label="Section 2 image Url"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <h6>Section 2 Image</h6>
                        </v-col>
                        <ModuleMedia
                            moduleType="image"
                            moduleOf="home_page_section_2_image"
                            moduleMultiple="false"
                            :moduleImages="module2Images"
                            :moduleImagesOld="module2ImagesOld"
                        ></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                        v-if="errors != undefined && errors.length >= 0"
                        role="alert"
                        v-bind:class="{ show: errors.length }"
                        class="alert fade alert-danger"
                    >
                      <div
                          class="alert-text"
                          v-for="(error, i) in errors"
                          :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Home Page Settings
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0"
              >
                <template v-slot:item.section_1_image_public_url="{ item }">
                  <div class="text-truncate">
                    <img
                        :src="item.section_1_image_public_url"
                        style="width: 50%"
                    />
                  </div>
                </template>
                <template v-slot:item.section_2_image_public_url="{ item }">
                  <div class="text-truncate">
                    <img
                        :src="item.section_2_image_public_url"
                        style="width: 50%"
                    />
                  </div>
                </template>
<!--                <template v-slot:item.new_release_book_names="{ item }">-->
<!--                  <div class="text-truncate">-->
<!--                    <span v-html="item.new_release_book_names"></span>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <template v-slot:item.best_seller_book_names="{ item }">-->
<!--                  <div class="text-truncate">-->
<!--                    <span v-html="item.best_seller_book_names"></span>-->
<!--                  </div>-->
<!--                </template>-->
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="hasPermission('homepagesettings.edit')"
                            color="primary"
                            @click="showEditDialog(item)"
                            dark
                            class="mr-2"
                            v-on="on"
                        >
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  HOMEPAGESETTINGS_LIST,
  UPDATE_HOMEPAGESETTING,
  CLEAR_HOMEPAGESETTING_ERRORS,
  GET_HOMEPAGESETTING_DETAILS
} from "@/core/services/store/homepagesettings.module";
import {BOOKS_SIMPLE_LIST} from "@/core/services/store/books.module";
// import {bus} from "@/main";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {bus} from "@/main";
// import Confirmation from "@/view/content/Confirmation";

export default {
  name: "home-page",
  components: {
    ModuleMedia
  },
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      module2Images: [],
      moduleImagesOld: [],
      module2ImagesOld: [],
      headers: [
        {
          text: "Section 1 image",
          value: "section_1_image_public_url",
          name: "section_1_image_public_url",
          filterable: false,
          sortable: false
        },
        {
          text: "Section 2 image",
          value: "section_2_image_public_url",
          name: "section_2_image_public_url",
          filterable: false,
          sortable: false
        },
        {
          text: "New release text",
          value: "new_release_text",
          name: "new_release_text",
          filterable: false,
          sortable: false
        },
        // {
        //   text: "New release books",
        //   value: "new_release_book_names",
        //   name: "new_release_book_names",
        //   filterable: false,
        //   sortable: false
        // },
        {
          text: "Best seller text",
          value: "best_seller_text",
          name: "best_seller_text",
          filterable: false,
          sortable: false
        },
        // {
        //   text: "Best seller books",
        //   value: "best_seller_book_names",
        //   name: "best_seller_book_names",
        //   filterable: false,
        //   sortable: false
        // },
        {
          text: "Shop by category text",
          value: "shop_by_category_text",
          name: "shop_by_category_text",
          filterable: false,
          sortable: false
        },
        {
          text: "Shop by category sub text",
          value: "shop_by_category_sub_text",
          name: "shop_by_category_sub_text",
          filterable: false,
          sortable: false
        },
        {
          text: "Blog text",
          value: "blog_text",
          name: "blog_text",
          filterable: false,
          sortable: false
        },
        {
          text: "Action",
          value: "actions",
          name: "name",
          filterable: true,
          sortable: false
        }
      ],
      validationRules: {
        valid: true,
        textRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 50) ||
              "Must be less than or equal to 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        subTextRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 100) ||
              "Must be less than or equal to 100 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select Contact(s)";
            else return true;
          }
        ],
      },
      items: [],
      dialog: false,
      editedItem: {},
      newReleaseBooksFilter: [],
      isNrBooksLoaded: false,
      bestSellerBooksFilter: [],
      isBsBooksLoaded: false,
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_HOMEPAGESETTING_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
      if (!val) this.module2Images = [];
      if (!val) this.module2ImagesOld = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Content Management", route: "home-page"},
      {title: "Home page"}
    ]);
    bus.$on("moduleImagesUploaded", moduleImages => {
      if (moduleImages.type === "home_page_section_1_image") {
        this.moduleImages = moduleImages.arr;
      } else {
        this.module2Images = moduleImages.arr;
      }
    });
    bus.$on("moduleImagesUploadedOld", moduleImagesOld => {
      if (moduleImagesOld.type === "home_page_section_1_image") {
        this.moduleImagesOld = moduleImagesOld.arr;
      } else {
        this.module2ImagesOld = moduleImagesOld.arr;
      }
    });
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
            .map(key => {
              return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? "created_at" : params.sortBy) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store
            .dispatch(HOMEPAGESETTINGS_LIST, {q: query, columns: this.columns})
            .then(res => {
              this.draw++;
              let items = res.records.data;
              let total = res.records.recordsFiltered;
              this.loading = !this.loading;
              this.isLoaded = !this.isLoaded;
              resolve({
                items,
                total
              });
            });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_HOMEPAGESETTING_DETAILS, item.id).then(data => {
        let hpSettingImages = [];
        let hpSetting2Images = [];
        this.editedItem = data.records || {};
        this.newReleaseBooksFilter = data.records.new_release_books;
        this.bestSellerBooksFilter = data.records.best_seller_books;
        if (data.records.section_1_image_public_url !== null) {
          hpSettingImages.push(data.records);
        }
        if (data.records.section_2_image_public_url !== null) {
          hpSetting2Images.push(data.records);
        }
        this.moduleImagesOld = hpSettingImages;
        this.module2ImagesOld = hpSetting2Images;
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      if (this.moduleImages.length > 0 || this.module2Images.length > 0) {
        let mFiles = [];
        let bFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        bFiles = this.module2Images;
        let cFiles = mFiles.concat(bFiles);
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
                .dispatch("delete_uploaded_image", {
                  files: cFiles,
                  type: "modal"
                })
                .then(() => {
                  that.moduleImages = [];
                  that.module2Images = [];
                  that.dialog = false;
                  bus.$emit("moduleImagesDeletedByModal");
                });
          }
        });
      } else {
        this.dialog = !this.dialog;
        // bus.$emit('moduleModalClosed');
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
     the default method is POST for creating a new item */
        let method = UPDATE_HOMEPAGESETTING;
        let id = item.id;
        this.$set(item, "section_1_image", this.moduleImages);
        this.$set(item, "section_2_image", this.module2Images);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_HOMEPAGESETTING;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_HOMEPAGESETTING_ERRORS);
        // save the record
        this.$store.dispatch(method, {slug: id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    booksNrFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isNrBooksLoaded = true;
        this.$store
            .dispatch(BOOKS_SIMPLE_LIST, {
              search: 'new_release',
              q: e.target.value
            })
            .then(data => {
              this.newReleaseBooksFilter = data.records.booksList;
              this.isNrBooksLoaded = false;
            });
      }
    },
    booksBsFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isBsBooksLoaded = true;
        this.$store
            .dispatch(BOOKS_SIMPLE_LIST, {
              search: 'best_seller',
              q: e.target.value
            })
            .then(data => {
              this.bestSellerBooksFilter = data.records.booksList;
              this.isBsBooksLoaded = false;
            });
      }
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.homepagesettings.errors
    })
  }
};
</script>
